import H1 from "../../components/Text/H1"

const About = () => {
    return (
        <div className="flex flex-col md:my-4 lg:my-6 text-center ">
            <H1>Xander Wells Portfolio</H1>
        </div>
    )
}

const Container = () => {

}

export default About